import BasePage from '../../../../../../components/table_page';
import Form from '../form';
import ProcessForm from '../../components/processForm';
import request from '../../../../../../utils/request';

export default {
  name: 'activity_overview',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    ProcessForm,
  },
  created() {
    this.getConfigList('tpm_activity_write_off_list');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 待提交 create
      // 审批中 approving
      // 审批通过 approved
      // 驳回 rejected
      // 流程追回 interrupt
      // 活动关闭 closed
      const { approveStatus, processCode } = row;
      if (
        code === 'edit'
        && approveStatus !== 'create'
        && approveStatus !== 'rejected'
        && approveStatus !== 'interrupt'
      ) {
        return false;
      }
      if (
        code === 'submit_approval'
        && approveStatus !== 'create'
        && approveStatus !== 'rejected'
      ) {
        return false;
      }
      return true;
    },
    // 删除前置
    beforeButtonClick({ val }) {
      let list = [];
      let state = true;
      if (val.code === 'delete' && this.selectRow.length > 0) {
        list = this.selectRow.map((v) => v.approveStatus);

        state = list.every((v) => v === 'create');
        if (!state) {
          this.$message.error('只有待提交的数据允许删除操作');
        }
      }
      return state;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          submitUrl: '',
          code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (code === 'submit_approval') {
        request
          .get('/tpm/tpmliqueuraudit/query', {
            id: row.id,
          })
          .then((res) => {
            const rowData = { ...res.result };
            rowData.saveType = '5';
            this.onSubmit({ row: rowData, submitUrl: '/tpm/tpmliqueuraudit/approve' });
          });
      } else if (code === 'process_log') {
        if (row.processCode) {
          this.formName = 'ProcessForm';
          this.modalConfig = {
            title: '流程日志',
          };

          this.formConfig = {
            ...this.formConfig,
            code,
            row: { processNo: row.processCode },
          };
          this.openFull();
        }
      }
    },
    // 提交审批
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
